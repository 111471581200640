"use client";

import { captureException } from "@sentry/react";
import { useEffect } from "react";

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
	useEffect(() => {
		captureException(error);
	}, [error]);

	return (
		<html>
			<body>
				<h2>Something went wrong! (global)</h2>
			</body>
		</html>
	);
}
